@tailwind base;
@tailwind components;
@tailwind utilities;

html, body {
  font-family: "Inter", sans-serif;
  @apply bg-neutral-100;
  @apply text-neutral-700;
}

.test {
  border: 2px solid red
}

@layer utilities {
  .scrollbar::-webkit-scrollbar {
    width: 0;
  }

  .number-input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  .dropdown-input {
    box-shadow:none;   
    outline:none;      
    -webkit-appearance: none;
    -moz-appearance: none;
    background: transparent;
    background-image: url(resources/down_arrow.png);
    background-size: 10px 5px;
    background-repeat: no-repeat;
    background-position-x: 25px;
    background-position-y: center;
  }

  .fade-in {
    animation: fadeIn 300ms ease-in-out;
  }
  
  @keyframes fadeIn {
    from { opacity: 0; }
    to   { opacity: 1; }
}
}
